.dataTable {
  width: 100%;
}
.dataTable tr th,
.dataTable tr td {
  padding: 5px 0;
}
.dataTable > tbody > tr {
  border-bottom: 1px solid var(--blue);
}
.dataTable > tbody > tr:first-child td {
  padding-bottom: 15px;
}
.dataTable > tbody > tr:nth-child(2) {
  font-weight: 600;
}
