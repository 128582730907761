@tailwind base;
@tailwind components;
@tailwind utilities;

/* Using Local Fonts */
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

:root {
  --darkblue: #00286d;
  --blue: #1976d2;
  --grey: #bdbdbd;
  --darkgrey: #8a8a8a;
  --lightgrey: #f0f0f0;
  --white: #ffffff;
  --black: #000000;
  --red: #dc2626;
  --green: rgb(49, 185, 49);

  font-family: Poppins;
  background-color: var(--lightgrey);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: 600;
}
h1 {
  font-size: 2em;
  margin-bottom: 0.67em;
}
h2 {
  font-size: 1.5em;
  margin-bottom: 0.83em;
}
h3 {
  font-size: 1.17em;
  margin-bottom: 1em;
}
h4 {
  font-size: 1em;
  margin-bottom: 1.33em;
}
h5 {
  font-size: 0.83em;
  margin-bottom: 1.67em;
}
h6 {
  font-size: 0.67em;
  margin-bottom: 2.33em;
}
