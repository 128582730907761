.MuiFormLabel-asterisk {
  color: red;
}

/* Select */

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: transparent !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  top: 0px !important;
}
.css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root > svg {
  top: 1px;
  right: 8px;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 7px !important;
}

/* Date Picker */

.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root {
  background-color: white !important;
}
