.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 112.06px);
}

.section {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 50px 0 var(--grey);
  padding: 25px;
  margin: 30px 20px;
  width: 350px;
}

/* heading */

.heading {
  color: var(--darkblue);
  text-align: center;
  margin-bottom: 30px;
}

/* button */

.btn {
  background-color: var(--white);
  border: 2px solid var(--white);
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  padding: 6px 12px;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 var(--darkgrey);
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}
.btn-blue {
  color: var(--white);
  background-color: var(--blue);
  box-shadow: 0 4px 10px 0 var(--darkgrey);
  border: 2px solid var(--blue);
}
.btn-red {
  color: var(--white);
  background-color: var(--red);
  box-shadow: 0 4px 10px 0 var(--darkgrey);
  border: 2px solid var(--red);
}
.btn-green {
  color: var(--white);
  background-color: var(--green);
  box-shadow: 0 4px 10px 0 var(--darkgrey);
  border: 2px solid var(--green);
}
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* overflow */

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover:enabled {
    border: 2px solid var(--black);
    background-color: transparent;
    transition: all 0.2s;
  }
  .btn-blue:hover:enabled {
    border: 2px solid var(--blue);
    color: var(--blue);
  }
  .btn-red:hover:enabled {
    border: 2px solid var(--red);
    color: var(--red);
  }
  .btn-green:hover:enabled {
    border: 2px solid var(--green);
    color: var(--green);
  }
}
